import { Input, Menu } from '@mantine/core'
import StyledMenu from 'src/components/Overlays/StyledMenu/StyledMenu'
import { DatePicker } from '@mantine/dates'
import dayjs from 'dayjs'
import IconCalendar from 'src/icons/IconCalendar'
import { useState } from 'react'

const preSelectedDateRanges = [
  {
    label: 'Last 2 days',
    value: 'last2Days',
    start: dayjs().subtract(2, 'day').startOf('day').toDate(),
    end: new Date(),
  },
  {
    label: 'Last 7 days',
    value: 'last7Days',
    start: dayjs().subtract(7, 'day').startOf('day').toDate(),
    end: new Date(),
  },
  // {
  //   label: 'Last 30 days',
  //   value: 'last30Days',
  //   start: dayjs().subtract(30, 'day').startOf('day').toDate(),
  //   end: new Date(),
  // },
  // {
  //   label: 'This month',
  //   value: 'thisMonth',
  //   start: dayjs().startOf('month').toDate(),
  //   end: new Date(),
  // },
  // {
  //   label: 'Last month',
  //   value: 'lastMonth',
  //   start: dayjs().subtract(1, 'month').startOf('month').toDate(),
  //   end: dayjs().subtract(1, 'month').endOf('month').toDate(),
  // },
]

const DateRangeMenu = ({
  startDate,
  setStartDate,
  setEndDate,
  endDate,
  showCalendar,
  setShowCalendar,
  handleChange,
  ...props
}) => {
  const [opened, setOpened] = useState(false)

  return (
    <StyledMenu closeOnItemClick={false} opened={opened} onChange={setOpened}>
      <Menu.Target>
        <Input
          leftSection={
            <IconCalendar className="fill-none stroke-doubleNickel-gray-700" />
          }
          w={300}
          placeholder="Date Range"
          onClick={() => setShowCalendar(false)}
          readOnly
          value={
            startDate && endDate
              ? `${dayjs(startDate).format('MM/DD/YYYY')} - ${
                  dayjs(endDate).format('MM/DD/YYYY') !== 'Invalid Date'
                    ? dayjs(endDate).format('MM/DD/YYYY')
                    : ''
                }`
              : ''
          }
        />
      </Menu.Target>
      <Menu.Dropdown w={300}>
        {showCalendar ? (
          <DatePicker
            className="flex flex-1 items-center justify-center"
            type="range"
            maxDate={new Date()}
            onChange={(value) => {
              const [start, end] = value
              const startTime = dayjs(start).startOf('day').toDate()
              const endTime = dayjs(end).endOf('day').toDate()
              setStartDate(startTime)
              setEndDate(endTime)
              handleChange([startTime, endTime])
              if (start && end) setOpened(false)
            }}
            {...props}
          />
        ) : (
          <>
            {preSelectedDateRanges.map((dateRange) => (
              <Menu.Item
                key={dateRange.value}
                onClick={() => {
                  setStartDate(dateRange.start)
                  setEndDate(dateRange.end)
                  handleChange([dateRange.start, dateRange.end])
                  setOpened(false)
                }}
              >
                {dateRange.label}
              </Menu.Item>
            ))}
            <Menu.Item
              onClick={(e) => {
                e.stopPropagation()
                setShowCalendar(true)
              }}
            >
              Select from calendar
            </Menu.Item>
          </>
        )}
      </Menu.Dropdown>
    </StyledMenu>
  )
}

export default DateRangeMenu
