import { Badge, Paper } from '@mantine/core'
import { getFilteredChartTooltipPayload } from '@mantine/charts'
import { formatCamelValue } from 'src/lib/formatters'

const AreaChartTooltip = ({ label, payload }) => {
  if (!payload) return null

  return (
    <Paper
      px="md"
      py="sm"
      withBorder
      shadow="md"
      radius="md"
      className="flex flex-col gap-2 bg-white"
    >
      <div className="text-sm font-semibold text-doubleNickel-gray-900">
        {label}
      </div>
      {getFilteredChartTooltipPayload(payload).map((item: any) => (
        <div
          key={item.name}
          className="flex flex-row items-center gap-4 text-sm font-medium text-doubleNickel-gray-700"
        >
          <Badge circle color={item.color} className="h-2 w-2" />
          <div>{formatCamelValue(item.name)}:</div>
          <div className="ml-auto">{item.value}</div>
        </div>
      ))}
    </Paper>
  )
}

export default AreaChartTooltip
