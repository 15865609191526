import { Badge } from '@mantine/core'
import { Sparkline } from '@mantine/charts'

const SparklineStatistic = ({
  title = '',
  data = [],
  value = 0,
  color = 'cyan',
}) => {
  return (
    <div className="flex flex-row items-center gap-4 text-sm text-doubleNickel-gray-900">
      <Badge circle color={color} className="h-2 w-2" />
      {title}
      <Sparkline
        w={120}
        h={30}
        curveType="monotone"
        data={data}
        trendColors={{
          positive: 'green.6',
          negative: 'red.6',
          neutral: 'gray.5',
        }}
        fillOpacity={0.4}
        strokeWidth={1}
        className="ml-auto"
      />
      <div className="font-medium">{value}</div>
    </div>
  )
}

export default SparklineStatistic
