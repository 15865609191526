import { series } from './PipelineAreaChart'
import SparklineStatistic from './SparklineStatistic'

const SparklineCard = ({ pipelineMetrics = [] }) => {
  return (
    <div className="flex h-[300px] flex-col gap-8 px-4">
      {series.map((item) => {
        return (
          <SparklineStatistic
            key={item.name}
            title={item.name}
            color={item.color}
            data={pipelineMetrics.map((metric) => {
              return metric[item.name]
            })}
            value={pipelineMetrics.reduce((acc, metric) => {
              return acc + metric[item.name]
            }, 0)}
          />
        )
      })}
    </div>
  )
}

export default SparklineCard
