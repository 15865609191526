export const GET_ACTIVITY_STATUS_LOG_METRICS = gql`
  query getActivityStatusLogMetrics($filters: StatusLogFiltersInput) {
    activityStatusLogMetrics(filters: $filters) {
      applicantStage
      linkType
      date
      hiringStage
      notInterestedReason
      disqualifiedReason
      terminatedReason
      count
    }
  }
`

export const GET_CONVERSION_STATUS_LOG_METRICS = gql`
  query getConversionStatusLogMetrics($filters: StatusLogFiltersInput) {
    conversionStatusLogMetrics(filters: $filters) {
      applicantStage
      linkType
      date
      hiringStage
      notInterestedReason
      disqualifiedReason
      terminatedReason
      count
    }
  }
`

export const GET_DISPOSITION_STATUS_LOG_METRICS = gql`
  query getDispositionStatusLogMetrics($filters: StatusLogFiltersInput) {
    dispositionStatusLogMetrics(filters: $filters) {
      applicantStage
      linkType
      date
      hiringStage
      notInterestedReason
      disqualifiedReason
      terminatedReason
      count
    }
  }
`
