import { MultiSelect } from '@mantine/core'

const StyledMultiselect = ({ ...props }) => {
  return (
    <MultiSelect
      clearable
      w={200}
      classNames={{
        pill: 'rounded-md bg-doubleNickel-gray-100',
        pillsList:
          'flex flex-row flex-nowrap gap-2 max-w-[300px] overflow-auto',
      }}
      searchable
      {...props}
    />
  )
}

export default StyledMultiselect
