import dayjs from 'dayjs'

const checkNaN = (value) => {
  return isFinite(value) ? value : 0
}

//Transform the metrics array into a format that can be used by the chart library - function courtesy of ChatGPT
export const generateMetricsByDateRange = (startDate, endDate, metrics) => {
  // Helper function to create a date range between two dates
  function createDateRange(start, end) {
    const dateArray = []
    let currentDate = new Date(start)
    end = new Date(end)

    while (currentDate <= end) {
      dateArray.push(new Date(currentDate).toISOString().split('T')[0]) // Store only the date part
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return dateArray
  }

  // Helper function to initialize the default object
  function getDefaultMetricsObject(date) {
    return {
      date: dayjs(date).format('MM/DD'),
      Prospect: 0,
      Terminated: 0,
      Hired: 0,
      'Not Interested': 0,
      Disqualified: 0,
    }
  }

  // Create date range
  const dateRange = createDateRange(startDate, endDate)

  // Create a map to store the metrics by date
  const metricsMap = {}

  // Iterate over the metrics array and populate the metricsMap
  metrics.forEach((metric) => {
    const metricDate = metric.date.split('T')[0] // Only use the date part
    if (!metricsMap[metricDate]) {
      metricsMap[metricDate] = getDefaultMetricsObject(metricDate)
    }

    // Update the count based on the applicant stage
    switch (metric.applicantStage) {
      case 'PROSPECT':
        metricsMap[metricDate]['Prospect'] += metric.count
        break
      case 'TERMINATED':
        metricsMap[metricDate]['Terminated'] += metric.count
        break
      case 'HIRED':
        metricsMap[metricDate]['Hired'] += metric.count
        break
      case 'NOT_INTERESTED':
        metricsMap[metricDate]['Not Interested'] += metric.count
        break
      case 'DISQUALIFIED':
        metricsMap[metricDate]['Disqualified'] += metric.count
        break
    }
  })

  // Generate the final array with default metrics for missing dates
  return dateRange.map((date) => {
    return metricsMap[date] || getDefaultMetricsObject(date)
  })
}

/**** These functions transform the metrics array into a format that can be used by the chart library ****/
/*** EXPECTED INPUT ARRAY:
[{applicantStage: 'TERMINATED'
count: 1
date: null
disqualifiedReason: null
hiringStage: null
linkType: 'TRUCKERS_REPORT'
notInterestedReason: null
terminatedReason: null}]
***/

/*** EXPECTED OUTPUT ARRAY:
[{CDL_LIFE: 6
COMPANY_WEBSITE: 4
CUSTOM: 1
FACEBOOK: 7
INDEED: 4
LINKEDIN: 7
M3TRAFFIC: 2
RANDALL_REILLY: 2
RECRUITER: 1
REFERRAL: 12
TOTAL: 51
TRUCKERS_REPORT: 5
type: 'LEAD'}]
***/

export function convertLeadConversionData(leadConversionData) {
  const newApplicantStageData = []
  const seenLinkTypes = new Set()

  // Gather all unique linkTypes from the input data
  leadConversionData.forEach((data) => {
    if (data.linkType) {
      seenLinkTypes.add(data.linkType)
    }
  })

  // Convert Set to an Array for ease of use later, sort by alphabetical order, and add a TOTAL field
  const linkTypesArray = Array.from(seenLinkTypes).sort()
  linkTypesArray.push('TOTAL')

  // Create an object for each type, including the ratios
  const fields = [
    'LEAD',
    // 'APPLICATION',
    'HIRED',
    'NOT_INTERESTED',
    'DISQUALIFIED',
    // 'LEAD_TO_APPLICATION',
    'LEAD_TO_HIRED',
    'LEAD_TO_NOT_INTERESTED',
    'LEAD_TO_DISQUALIFIED',
  ]

  // Initialize an object for each type with all LinkTypeDisplay attributes set to 0
  fields.forEach((field) => {
    const newObject = { type: field }
    linkTypesArray.forEach((linkType) => {
      newObject[linkType] = 0
    })
    newApplicantStageData.push(newObject)
  })

  // Populate the newApplicantStageData based on the input data
  leadConversionData.forEach((data) => {
    const { applicantStage, count, linkType, hiringStage } = data

    if (applicantStage !== 'PROSPECT') {
      // Find the corresponding type object and set the count for the linkType
      const targetObject = newApplicantStageData.find(
        (item) => item.type === applicantStage
      )
      if (targetObject && linkType) {
        targetObject[linkType] = count
        targetObject['TOTAL'] += count
      }
    } else if (
      hiringStage === 'LEAD'
      // || hiringStage === 'APPLICATION'
    ) {
      // Find the corresponding type object and set the count for the linkType
      const targetObject = newApplicantStageData.find(
        (item) => item.type === hiringStage
      )
      if (targetObject && linkType) {
        targetObject[linkType] = count
        targetObject['TOTAL'] += count
      }
    }
  })

  //Calculate the ratios based on the counts
  linkTypesArray.forEach((linkType) => {
    const leadCount = newApplicantStageData.find(
      (item) => item.type === 'LEAD'
    )[linkType]

    const notInterestedCount = newApplicantStageData.find(
      (item) => item.type === 'NOT_INTERESTED'
    )[linkType]

    const disqualifiedCount = newApplicantStageData.find(
      (item) => item.type === 'DISQUALIFIED'
    )[linkType]

    // const applicationCount = newApplicantStageData.find(
    //   (item) => item.type === 'APPLICATION'
    // )[linkType]

    const hiredCount = newApplicantStageData.find(
      (item) => item.type === 'HIRED'
    )[linkType]

    newApplicantStageData.find(
      (item) => item.type === 'LEAD_TO_NOT_INTERESTED'
    )[linkType] = `${checkNaN((notInterestedCount / leadCount) * 100).toFixed(
      2
    )}%`
    // newApplicantStageData.find((item) => item.type === 'LEAD_TO_APPLICATION')[
    //   linkType
    // ] = `${checkNaN((applicationCount / leadCount) * 100).toFixed(2)}%`
    newApplicantStageData.find((item) => item.type === 'LEAD_TO_DISQUALIFIED')[
      linkType
    ] = `${checkNaN((disqualifiedCount / leadCount) * 100).toFixed(2)}%`
    newApplicantStageData.find((item) => item.type === 'LEAD_TO_HIRED')[
      linkType
    ] = `${checkNaN((hiredCount / leadCount) * 100).toFixed(2)}%`
  })

  return newApplicantStageData
}

export function convertNotInterestedData(dispositionnData) {
  const newApplicantStageData = []
  const seenLinkTypes = new Set()
  const seenNotInterestedReasons = new Set()

  const notInterestedData = dispositionnData.filter(
    (item) => item.applicantStage === 'NOT_INTERESTED'
  )

  // Gather all unique linkTypes from the input data
  notInterestedData.forEach((data) => {
    if (data.linkType) {
      seenLinkTypes.add(data.linkType)
    }
  })

  // Gather all unique Not Interested Reasons from the input data
  notInterestedData.forEach((data) => {
    if (data.notInterestedReason) {
      seenNotInterestedReasons.add(data.notInterestedReason)
    }
  })

  // Convert Sets to Arrays for ease of use later, alphabetically sorted
  const linkTypesArray = Array.from(seenLinkTypes).sort()
  linkTypesArray.push('TOTAL')
  const notInterestedReasonsArray = Array.from(seenNotInterestedReasons).sort()

  // Initialize an object for each type with all LinkTypeDisplay attributes set to 0
  notInterestedReasonsArray.forEach((field) => {
    const newObject = { NOT_INTERESTED_REASON: field }
    linkTypesArray.forEach((linkType) => {
      newObject[linkType] = 0
    })
    newApplicantStageData.push(newObject)
  })

  // Populate the newApplicantStageData based on the input data
  notInterestedData.forEach((data) => {
    const { notInterestedReason, count, linkType } = data

    // Find the corresponding type object and set the count for the linkType
    const targetObject = newApplicantStageData.find(
      (item) => item.NOT_INTERESTED_REASON === notInterestedReason
    )
    if (targetObject && linkType) {
      targetObject[linkType] = count
      targetObject['TOTAL'] += count
    }
  })

  return newApplicantStageData
}

export function convertDisqualifiedData(dispositionnData) {
  const newApplicantStageData = []
  const seenLinkTypes = new Set()
  const seenDisqualifiedReasons = new Set()

  const disqualifiedData = dispositionnData.filter(
    (item) => item.applicantStage === 'DISQUALIFIED'
  )

  // Gather all unique linkTypes from the input data
  disqualifiedData.forEach((data) => {
    if (data.linkType) {
      seenLinkTypes.add(data.linkType)
    }
  })

  // Gather all unique Disqualified Reasons from the input data
  disqualifiedData.forEach((data) => {
    if (data.disqualifiedReason) {
      seenDisqualifiedReasons.add(data.disqualifiedReason)
    }
  })

  // Convert Sets to Arrays for ease of use later, alphabetically sorted, and add a TOTAL field
  const linkTypesArray = Array.from(seenLinkTypes).sort()
  linkTypesArray.push('TOTAL')
  const disqualifiedReasonsArray = Array.from(seenDisqualifiedReasons).sort()

  // Initialize an object for each type with all LinkTypeDisplay attributes set to 0
  disqualifiedReasonsArray.forEach((field) => {
    const newObject = { DISQUALIFIED_REASON: field }
    linkTypesArray.forEach((linkType) => {
      newObject[linkType] = 0
    })
    newApplicantStageData.push(newObject)
  })

  // Populate the newApplicantStageData based on the input data
  disqualifiedData.forEach((data) => {
    const { disqualifiedReason, count, linkType } = data

    // Find the corresponding type object and set the count for the linkType
    const targetObject = newApplicantStageData.find(
      (item) => item.DISQUALIFIED_REASON === disqualifiedReason
    )

    if (targetObject && linkType) {
      targetObject[linkType] = count
      targetObject['TOTAL'] += count
    }
  })

  return newApplicantStageData
}

export function calculateTotals(disqualifiedData, applicantStage) {
  const result = {}
  const filteredArray = disqualifiedData.filter(
    (item) => item.applicantStage === applicantStage
  )
  filteredArray.forEach((data) => {
    if (data.linkType in result) {
      result[data.linkType] += data.count
    } else {
      result[data.linkType] = data.count
    }
  })

  return result
}
