import { calculateTotals, convertDisqualifiedData } from '../utils'
import { Table } from '@mantine/core'
import { LinkTypeDisplay } from 'src/graphql/types/trackingLinks'
import { formatSnakeValue } from 'src/lib/formatters'
import { cn } from 'src/utils'

const DisqualifiedTable = ({ dispositonMetrics = [], ...props }) => {
  const disqualifiedData = convertDisqualifiedData(dispositonMetrics)
  const totals = calculateTotals(dispositonMetrics, 'DISQUALIFIED')

  const calculateCellValue = (element, column) => {
    if (column.accessor === 'DISQUALIFIED_REASON') {
      return formatSnakeValue(element[column.accessor])
    } else if (column.accessor === 'TOTAL') {
      return element[column.accessor]
    } else if (totals[column.accessor] === 0) {
      return 0
    } else {
      return (
        ((element[column.accessor] / totals[column.accessor]) * 100).toFixed(
          0
        ) + '%'
      )
    }
  }

  const calculateTotalCellValue = (column) => {
    if (column.accessor === 'DISQUALIFIED_REASON') {
      return 'Total'
    } else if (column.accessor === 'TOTAL') {
      return disqualifiedData.reduce((acc, curr) => acc + curr['TOTAL'], 0)
    } else {
      return totals[column.accessor]
    }
  }

  //Generate the columns for the table based on the data
  let disqualifiedColumns =
    disqualifiedData.length > 0
      ? Object.keys(disqualifiedData[0]).map((key) => {
          return {
            accessor: key,
          }
        })
      : []

  //This function is used to color the cells that are above their expected percentage
  const addColorOverThreshold = (element, column) => {
    return (
      element[column.accessor] / totals[column.accessor] > 0.2 &&
      'font-semibold text-doubleNickel-error-400'
    )
  }

  //Generate the columns for the table
  const disqualifiedCols = disqualifiedColumns.map((element, index) => (
    <Table.Th
      key={element.accessor + index}
      className="text-nowrap bg-doubleNickel-gray-50 py-4 font-semibold text-doubleNickel-gray-600"
    >
      {LinkTypeDisplay[element.accessor] || formatSnakeValue(element.accessor)}
    </Table.Th>
  ))

  //Generate the rows for the table
  const disqualifiedRows = disqualifiedData.map((element, index) => (
    <Table.Tr key={element.DISQUALIFIED_REASON + index}>
      {disqualifiedColumns.map((column, index) => (
        <Table.Td
          key={index}
          className={cn(
            'py-3',
            column.accessor === 'DISQUALIFIED_REASON' &&
              'w-64 bg-doubleNickel-gray-50',
            column.accessor === 'TOTAL' && 'font-semibold',
            addColorOverThreshold(element, column)
          )}
        >
          {calculateCellValue(element, column)}
        </Table.Td>
      ))}
    </Table.Tr>
  ))

  //Add the total row to the table
  disqualifiedRows.push(
    <Table.Tr
      key="total"
      className="border-t-4 border-doubleNickel-gray-200 font-semibold"
    >
      {disqualifiedColumns.map((column, index) => (
        <Table.Td
          key={index}
          className={cn(
            'py-3',
            column.accessor === 'DISQUALIFIED_REASON' &&
              'w-64 bg-doubleNickel-gray-50'
          )}
        >
          {calculateTotalCellValue(column)}
        </Table.Td>
      ))}
    </Table.Tr>
  )

  return (
    <Table.ScrollContainer minWidth={300}>
      <Table
        highlightOnHover
        stickyHeader
        {...props}
        classNames={{
          tbody: 'text-nowrap',
        }}
      >
        <Table.Thead>
          <Table.Tr>{disqualifiedCols}</Table.Tr>
        </Table.Thead>
        <Table.Tbody>{disqualifiedRows}</Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  )
}

export default DisqualifiedTable
