import { useState } from 'react'
import StyledMultiselect from 'src/components/StyledMultiselect/StyledMultiselect'
import { LinkType, LinkTypeDisplay } from 'src/graphql/types/trackingLinks'

export const leadSourceOptions = Object.keys(LinkType).map((key) => ({
  value: LinkType[key],
  label: LinkTypeDisplay[LinkType[key]],
}))

const LeadSourceMultiselect = ({
  selectedLeadSource,
  handleChange,
  ...props
}) => {
  const [chosenLeadSource, setChosenLeadSource] = useState(selectedLeadSource)

  return (
    <StyledMultiselect
      value={chosenLeadSource}
      placeholder={chosenLeadSource.length > 0 ? '' : 'Lead Sources'}
      data={leadSourceOptions}
      onChange={(value) => {
        setChosenLeadSource(value)
        handleChange(value)
      }}
      {...props}
    />
  )
}

export default LeadSourceMultiselect
