import Empty from 'src/components/Feedback/Empty/Empty'
import { convertLeadConversionData } from '../utils'
import { Table } from '@mantine/core'
import { LinkTypeDisplay } from 'src/graphql/types/trackingLinks'
import { formatSnakeValue } from 'src/lib/formatters'
import { cn } from 'src/utils'

const LeadConversionTable = ({ conversionLogMetrics = [], ...props }) => {
  const data = convertLeadConversionData(conversionLogMetrics)

  //Generate the columns for the table based on the data
  let leadSourceColumns =
    data.length > 0
      ? Object.keys(data[0]).map((key) => {
          return {
            accessor: key,
          }
        })
      : []

  //Generate the columns for the table
  const tableCols = leadSourceColumns.map((element, index) => (
    <Table.Th
      key={element.accessor + index}
      className={cn(
        'text-nowrap bg-doubleNickel-gray-50 py-4 font-semibold text-doubleNickel-gray-600'
      )}
    >
      {LinkTypeDisplay[element.accessor] ?? formatSnakeValue(element.accessor)}
    </Table.Th>
  ))

  //Generate the rows for the table
  const tableRows = data.map((element, index) => (
    <Table.Tr
      key={element.type + index}
      className={cn(
        element.type.includes('TO') && 'font-semibold',
        element.type === 'LEAD_TO_HIRED' &&
          'border-t-4 border-doubleNickel-gray-200'
      )}
    >
      {leadSourceColumns.map((column, index) => (
        <Table.Td
          key={index}
          className={cn(
            'py-3',
            column.accessor === 'type' && 'w-64 bg-doubleNickel-gray-50',
            column.accessor === 'TOTAL' && 'font-semibold'
          )}
        >
          {column.accessor === 'type'
            ? formatSnakeValue(element[column.accessor])
            : element[column.accessor]}
        </Table.Td>
      ))}
    </Table.Tr>
  ))

  return (
    <div className="w-[90vw] overflow-auto">
      {conversionLogMetrics.length === 0 ? (
        <Empty
          title="No data available"
          subtitle="There is no data available for the selected date range."
        />
      ) : (
        <Table.ScrollContainer minWidth={300}>
          <Table
            highlightOnHover
            stickyHeader
            {...props}
            classNames={{
              tbody: 'text-nowrap',
            }}
          >
            <Table.Thead>
              <Table.Tr>{tableCols}</Table.Tr>
            </Table.Thead>
            <Table.Tbody>{tableRows}</Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      )}
    </div>
  )
}

export default LeadConversionTable
