export const sampleData = [
  {
    date: '09/22',
    Prospects: 14,
    'Not Interested': 6,
    Disqualified: 6,
    Hired: 2,
    Terminated: 1,
  },
  {
    date: '09/23',
    Prospects: 12,
    'Not Interested': 4,
    Disqualified: 6,
    Hired: 4,
    Terminated: 1,
  },
  {
    date: '09/24',
    Prospects: 15,
    'Not Interested': 4,
    Disqualified: 6,
    Hired: 3,
    Terminated: 1,
  },
  {
    date: '09/25',
    Prospects: 13,
    'Not Interested': 4,
    Disqualified: 5,
    Hired: 3,
    Terminated: 1,
  },
  {
    date: '09/26',
    Prospects: 17,
    'Not Interested': 5,
    Disqualified: 5,
    Hired: 1,
    Terminated: 1,
  },
  {
    date: '09/27',
    Prospects: 14,
    'Not Interested': 6,
    Disqualified: 6,
    Hired: 2,
    Terminated: 0,
  },
  {
    date: '09/28',
    Prospects: 12,
    'Not Interested': 4,
    Disqualified: 6,
    Hired: 4,
    Terminated: 1,
  },
]

export const sampleSeries = [
  {
    name: 'Prospects',
    color: 'cyan.6',
  },
  { name: 'Not Interested', color: 'cyan.4' },
  { name: 'Disqualified', color: 'red.4' },
  { name: 'Hired', color: 'green.4' },
  { name: 'Terminated', color: 'red.6' },
]
