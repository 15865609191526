import StyledAreaChart from './StyledAreaChart/StyledAreaChart'

export const series = [
  {
    name: 'Prospect',
    color: '#77BEE7',
  },
  { name: 'Not Interested', color: '#D0D5DD' },
  { name: 'Disqualified', color: '#FEC84B' },
  { name: 'Hired', color: '#75E0A7' },
  { name: 'Terminated', color: '#FDA29B' },
]

const PipelineAreaChart = ({ ...props }) => {
  return <StyledAreaChart useSampleData={false} series={series} {...props} />
}

export default PipelineAreaChart
