import { useContext, useState } from 'react'
import StyledMultiselect from 'src/components/StyledMultiselect/StyledMultiselect'
import { RecruitersContext } from 'src/context/RecruitersContext'

const RecruitersMultiselect = ({
  selectedRecuiters = [],
  handleChange,
  ...props
}) => {
  const [chosenRecruiters, setChosenRecruiters] = useState(selectedRecuiters)
  const recruiters = useContext(RecruitersContext)

  return (
    <StyledMultiselect
      data={recruiters}
      value={chosenRecruiters}
      placeholder={chosenRecruiters?.length > 0 ? '' : 'Recruiters'}
      onChange={(value) => {
        handleChange(value)
        setChosenRecruiters(value)
      }}
      {...props}
    />
  )
}

export default RecruitersMultiselect
