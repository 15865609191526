import NotInterestedTable from './NotInterestedTable'
import DisqualifiedTable from './DisqualifiedTable'
import Empty from 'src/components/Feedback/Empty/Empty'

const DispositionTable = ({ dispositonMetrics = [], ...props }) => {
  return (
    <div className="w-[90vw] overflow-auto">
      {dispositonMetrics.length === 0 ? (
        <Empty
          title="No data available"
          subtitle="There is no data available for the selected date range."
        />
      ) : (
        <>
          <NotInterestedTable dispositonMetrics={dispositonMetrics} />
          <DisqualifiedTable dispositonMetrics={dispositonMetrics} />
        </>
      )}
    </div>
  )
}

export default DispositionTable
