import { useQuery } from '@redwoodjs/web'
import { useState } from 'react'
import StyledMultiselect from 'src/components/StyledMultiselect/StyledMultiselect'
import { GET_JOB_LISTINGS_ONLY } from 'src/graphql/joblistings.graphql'
import { Loader } from '@mantine/core'

const JobListingsMultiselect = ({
  selectedJobListings = [],
  selectedRecruiters = [],
  handleChange,
  ...props
}) => {
  const [chosenJobListings, setChosenJobListings] =
    useState(selectedJobListings)
  const [page, setPage] = useState(1)
  const LIMIT = 10

  /**********************    QUERIES    **********************/
  const { data: jobListingsResponse, loading: jobListingsLoading } = useQuery(
    GET_JOB_LISTINGS_ONLY,
    {
      variables: {
        pageInfo: { offset: (page - 1) * LIMIT, limit: LIMIT },
        filters: {
          recruiters: selectedRecruiters,
        },
      },
    }
  )

  const jobListingOptions =
    jobListingsResponse?.jobListings.items?.map((jl) => ({
      value: jl.jobListingId,
      label: jl.title,
    })) || []

  return (
    <StyledMultiselect
      value={chosenJobListings}
      placeholder={chosenJobListings.length > 0 ? '' : 'Job Listings'}
      rightSection={jobListingsLoading ? <Loader size="1rem" /> : null}
      data={jobListingOptions}
      onChange={(value) => {
        setChosenJobListings(value)
        handleChange(value)
      }}
      {...props}
    />
  )
}

export default JobListingsMultiselect
